import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import BlogPosts from '../components/BlogPosts'
import SEO from "../components/seo/seo-new"
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

// Query for the Blog Home content in Prismic
export const query = graphql`
  query myEcomQuery ($language: String!) {
    allPrismicPost(sort: {fields: last_publication_date, order: DESC} filter: {lang: {eq: "en-us"}}){
      edges {
        node {
          url
          id
          uid
          last_publication_date
          type
          data {
            meta_description
            meta_title
            title {
              richText
            }
            image {
              url
            }
            date_updated
            date_posted
            picture {
              url
            }
            author_name {
              text
            }
            description {
              text
            }
            body {
              ... on PrismicPostDataBodyText {
                id
                slice_label
                slice_type
                primary {
                  text {
                    richText
                  }
                }
              }

            }
          }
        }
      }
    }
    allPrismicBlogs {
        nodes {
          data {
            name
          }
        uid
        }
    }
     locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`


export const Homepage = ({ data, pageContext }) => {
  const { t } = useTranslation();
  const lang = data.locales.edges[0].node.language

  if (!data) return null
  // Define the Blog Home & Blog Post content returned from Prismic
  const posts = data.allPrismicPost.edges

  const blogNames = data.allPrismicBlogs.nodes

  const title = t(`ecommerce Blog by taxomate`)
  const description = t(`Best blog for ecommerce sellers, including tips, tools, and tricks for accounting and bookkeeping.`)

  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        image="https://res.cloudinary.com/taxomate/image/upload/v1630007980/taxomate-social-card_tozy38.jpg"
        pathname="/ecommerce-seller-blog/"
        originalPathname="/ecommerce-seller-blog/"
        lang={lang}
        alternate
      />


      <div className="relative bg-gray-50 p-10 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className='text-center pb-10'>
          <h1 className="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-br uppercase from-blue-600 to-blue-500">
            Ecommerce Seller Blog
          </h1>
          <p className='text-xl font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-gray-600 to-gray-500 pt-2'>by taxomate</p>
        </div>

        <div className='container pt-6 mx-auto'>
        <div className='flex flex-wrap'>
          <div className='md:w-1/6 w-full'>
              <nav class="pb-5" aria-label="Sidebar">
                <div className='border-solid'>
                <p href="#" class="text-blue-600 flex items-center px-3 py-2 text-sm font-medium" aria-current="page">
                    <span class="truncate"> <Trans>Filter By Resource</Trans></span>
                  </p>
                </div>
                {blogNames.map((blogName) => (

                <Link to={`/${blogName.uid}`} class="text-gray-500 hover:bg-gray-50 hover:text-gray-900 flex items-center px-3 py-2 text-sm font-medium rounded-md">
                    <span class=" break-words">{blogName.data.name} <Trans>Resources</Trans></span>
                 </Link>

                ))
                }
              </nav>
            </div>

          <div className='md:w-5/6 w-full'>

        <div className="relative max-w-7xl mx-auto">


                <BlogPosts posts={posts} />

            </div>
          </div>
        </div>
        </div>
      </div>
    </Layout>
  )
}

export default Homepage